<template>
  <div>
    <tab-bar-wrapper @newRow="newPart"/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        name="master"
        :context-menu-items="contextMenuItems"
        :columns="columns"
        :query="query"
        :filter="filter"
        :component-name="$options.name"
        :nested-grid="true"
        @ready="ready"
        @cell-key-down="gridKbEventSender"
        @setMasterObject="selectedPart = $event"
      />
    </div>
    <v-dialog
      v-model="localDialog"
      lazy
      max-width="1000px"
    >
      <master-part-summary v-if="localDialog" :id="selectedPartId" @close="localDialog = false" @success="redraw"/>
    </v-dialog>
  </div>
</template>

<script>
import grid from '../../components/mixins/grid'
import tabBarWrapper from '../../components/wrappers/tabBarWrapper'
import gridWrapper from '../../components/wrappers/gridWrapper'
import columns from '../../components/mixins/columns'
import { MASTER_PARTS } from '../../api/graphql/Constants/Inventory'
import { COLUMNS__INVENTORY_MASTER } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__INVENTORY_MASTER } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__INVENTORY_MASTER } from '@/lib/agGridKbShortCuts'
import MasterPartSummary from '@/components/cards/MasterPartSummary'

export default {
  name: 'master',
  mixins: [grid, columns],
  components: {
    'tab-bar-wrapper': tabBarWrapper,
    'grid-wrapper': gridWrapper,
    'master-part-summary': MasterPartSummary
  },
  computed: {
    sheet () {
      return this.$store.state.grid.sheet
    },

    showSheet () {
      return this.sheet.app === 'master'
    },

    selectedPartId () {
      return this.selectedPart?.id ?? ''
    }
  },
  data () {
    return {
      contextMenuItems: (params) => CONTEXT_MENU__INVENTORY_MASTER(params, this.contextMenuCallback),
      columns: COLUMNS__INVENTORY_MASTER,
      query: MASTER_PARTS,
      filter: [],
      selectedPart: null,

      localDialog: false
    }
  },
  methods: {
    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__INVENTORY_MASTER(params, this.contextMenuCallback), [])
    },

    contextMenuCallback () {
      return {
        editPart: (part) => this.editPart(part)
      }
    },

    redraw () {
      this.gapi.redrawRows()
    },

    newPart () {
      this.$store.dispatch('grid/changeSheet', { app: 'topbar', component: 'newPart' })
    },

    editPart (part) {
      this.selectedPart = part
      this.localDialog = true
    }
  }
}
</script>

<style scoped>

</style>
