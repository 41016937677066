























































import { defineComponent, provide, reactive, ref } from '@vue/composition-api'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { ApolloClients, useMutation, useQuery } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { GET_MASTER_PART_SUMMARY, UPDATE_PART } from '@/api/graphql/Constants/Parts'
import {
  Mutation, MutationUpdate__Parts_PartArgs,
  Query,
  SingleQueryInput,
  Update__Parts_Part__Input
} from '@/models/generated/graphql/ErpBackend'
import tAlert from '@/components/notifications/tAlert.vue'

export default defineComponent({
  name: 'MasterPartSummary',
  components: {
    'cancel-button': CancelButton,
    'submit-button': SubmitButton,
    'alert': tAlert
  },
  props: {
    id: { // uses this to grab information on the part
      type: [String, Number],
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    const canEdit = ref(false)
    const success = ref(false)

    const alert = reactive({
      message: '',
      type: 'info'
    })
    function resetAlert () {
      alert.message = ''
      alert.type = 'info'
    }
    function setAlert (m: string, t: string) {
      alert.message = m
      alert.type = t
    }

    const editInput: Update__Parts_Part__Input = reactive({
      id: `${props.id}`
    })
    const part = reactive(
      {
        id: props.id,
        pn: '',
        has_battery: false,
        hstus_code: '',
        hs_code: '',
        sku: ''
      }
    )
    const fields = ref([])
    function UpdateEdits (key: string, value: string): void {
      // @ts-ignore
      editInput[key] = value
      canSubmit.value = checkSubmit()
    }

    // query for part
    const {
      loading,
      onResult
    } = useQuery<Query, SingleQueryInput>(GET_MASTER_PART_SUMMARY,
      () => ({
        id: `${props.id}`
      }),
      {
        enabled: props.id !== ''
      }
    )
    onResult(response => {
      const addIt = (value: string) => {
        if (typeof value === typeof 'string') {
          return 'string'
        } else if (typeof value === typeof 1) {
          return 'number'
        } else if (typeof value === typeof false) {
          return 'boolean'
        }
        return false
      }

      const excluded = (key: string) => {
        return ['id', 'pn', 'type', '__typename'].indexOf(key.trim()) === -1
      }

      const getCounter = (key: string) => {
        if (key === 'hs_code') {
          return 6
        }
        if (['htsus_code', 'schedule_b_code'].indexOf(key) > -1) {
          return 10
        }
        if (key === 'upc') {
          return 12
        }
        if (key === 'ean') {
          return 13
        }
        if (key === 'sku') {
          return 30
        }
        return undefined
      }

      const getMask = (key: string) => {
        if (['hs_code', 'htsus_code', 'upc', 'ean', 'schedule_b_code'].indexOf(key) > -1) {
          return '#'.repeat(getCounter(key) ?? 0)
        }
        if (key === 'secondary_pn') {
          return 'NNNNNNNNNNNNNNNNNNNNNNNNN'
        }
        if (key === 'sku') {
          return 'N'.repeat(30)
        }
        return undefined
      }

      if (response?.data?.parts_part) {
        for (const key in response.data.parts_part) {
          // @ts-ignore
          part[key] = response.data.parts_part[key]
          // @ts-ignore
          const type = addIt(part[key])
          // @ts-ignore
          if (type && fields.value.findIndex(f => f.key === key) === -1) {
            excluded(key) && fields.value.push({
              // @ts-ignore
              key: key,
              // @ts-ignore
              value: part[key],
              // @ts-ignore
              type: type,
              // @ts-ignore
              label: key.replace(/_/g, ' ').toUpperCase(),
              // @ts-ignore
              mask: getMask(key),
              // @ts-ignore
              counter: getCounter(key)
            })
          }
        }
      }
    })
    // mutation for editing part
    const { mutate, loading: mutating, onDone, onError } = useMutation<Mutation, MutationUpdate__Parts_PartArgs>(UPDATE_PART,
      () => ({
        variables: {
          input: editInput
        }
      })
    )
    onDone(() => {
      success.value = true
      canEdit.value = false
      emit('success')
      setAlert('Successfully edited part', 'success')
    })
    onError((error) => {
      setAlert(error.message, 'error')
    })

    function cancel () {
      for (const key in editInput) {
        // @ts-ignore
        editInput[key] = part[key]
      }
      canEdit.value = false
      success.value = false
      resetAlert()
      emit('close')
    }

    const canSubmit = ref(false)
    function checkSubmit () {
      let r = false
      for (const key in editInput) {
        // @ts-ignore
        if (editInput[key] !== part[key]) r = true
      }
      return r
    }

    return {
      alert,
      canEdit,
      canSubmit,
      success,
      part,
      editInput,
      loading,
      UpdateEdits,
      mutating,
      mutate,
      cancel,
      fields
    }
  }
})
